import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import BackButton from '../../Components/BackButton';
import {navigate} from 'gatsby';
import Row from '../../Widgets/Row';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import Tree from '../../Widgets/Tree';
import {Col, Row as AntRow, Input, Message, Checkbox} from 'antd';

export default function AddRolePage({location}) {
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [roleAccessMenu] = useOutlet('roleAccessMenu');
  const [treeData, setTreeData] = useState([]);
  const [selectedMenuKey, setSelectedMenuKey] = useState([]);
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [comment, setComment] = useState('');
  const [isAmountVisible, setIsAmountVisible] = useState(false);

  const convertSidebarToTreeData = (data = []) => {
    if (!data) {
      return;
    }
    return data.map((item) => {
      const newItem = {
        key: Number(item.id),
        title: item.title,
      };
      if (item.children && item.children.length > 0) {
        newItem.children = convertSidebarToTreeData(item.children);
      }
      return newItem;
    });
  };

  const handleCheckChange = (selectedKeys) => {
    setSelectedMenuKey(selectedKeys);
  };

  const addRole = async () => {
    try {
      setLoading(true);

      if (!name || !slug) {
        Message.error('請填寫所有欄位');
        setLoading(false);
        return false;
      }

      let res = await actions.addRole({
        name,
        slug,
        comment,
        menus: selectedMenuKey,
        is_amount_visible: isAmountVisible,
      });
      Message.success('新增角色成功');
      navigate('/role/');
    } catch (err) {
      console.warn(err);
      Message.error('新增失敗');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (roleAccessMenu) {
      setTreeData(convertSidebarToTreeData(roleAccessMenu));
    } else {
      setTreeData([]);
    }
  }, [roleAccessMenu]);

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton />
        <div style={{flex: 1}} />
        <Button
          style={{marginLeft: 10}}
          type="primary"
          onClick={() => addRole()}>
          新增角色
        </Button>
      </Row>
      <Row style={{marginBottom: 20}}>
        <Block>
          <h2>角色資訊</h2>
          <div>
            <div className="row">
              <h4>角色名稱</h4>
              <Input
                placeholder="角色名稱"
                disabled={loading}
                value={name}
                onChange={(e) => {
                  let value = e.target.value;
                  setName(value);
                }}
              />
            </div>
            <div className="row">
              <h4>角色標籤</h4>
              <Input
                placeholder="僅限輸入英文"
                disabled={loading}
                value={slug}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                  setSlug(value);
                }}
              />
            </div>
            <div className="row">
              <h4>角色備註</h4>
              <Input
                placeholder="角色備註"
                disabled={loading}
                value={comment}
                onChange={(e) => {
                  let value = e.target.value;
                  setComment(value);
                }}
              />
            </div>
          </div>
        </Block>
      </Row>
      <AntRow gutter={[20, 20]}>
        <Col span={12}>
          <Block>
            <h2>選單權限</h2>
            <Block>
              <h2>角色權限</h2>
              <Tree source={treeData} onCheckChange={handleCheckChange} />
            </Block>
          </Block>
        </Col>
        <Col span={12}>
          <Block>
            <h2>特殊權限</h2>
            <Row>
              <Checkbox
                onChange={(e) => setIsAmountVisible(e.target.checked)}
                style={{
                  marginRight: '10px',
                }}
              />
              <h4>可否查看訂單金額</h4>
            </Row>
          </Block>
        </Col>
      </AntRow>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
